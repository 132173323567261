import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import { IRepresentative } from '#/types/representative';
import queryClient from '#/api/query-client';

const baseUrl = 'representative';

export const RepresentativeEndpoints = {
  getRepresentatives: () => `${baseUrl}/list/`,
  getRepresentative: (id: number | string | undefined) =>
    `${baseUrl}/single/${id}/`,
  updateRepresentative: (id: number | undefined) => `${baseUrl}/update/${id}/`,
  deleteRepresentative: (id: number) => `${baseUrl}/delete/${id}/`,
  addRepresentative: () => `${baseUrl}/create/`,
};

export const invalidateRepresentativeQueries = {
  getRepresentatives: () => {
    queryClient
      .invalidateQueries(RepresentativeEndpoints.getRepresentatives())
      .then((r) => r);
  },
  getRepresentative: (id: number) => {
    queryClient
      .invalidateQueries(RepresentativeEndpoints.getRepresentative(id))
      .then((r) => r);
  },
};

export const useGetRepresentatives = () =>
  useGet<IRepresentative[]>(RepresentativeEndpoints.getRepresentatives());

export const useGetRepresentative = (
  id: number | string | undefined,
  enabled: boolean
) =>
  useGet<IRepresentative>(
    RepresentativeEndpoints.getRepresentative(id),
    {},
    {
      enabled,
    }
  );

export const useUpdateRepresentative = (id: number | undefined) =>
  usePut<IRepresentative, IRepresentative>(
    RepresentativeEndpoints.updateRepresentative(id)
  );

export const useDeleteRepresentative = (id: number) =>
  useDelete<IRepresentative>(RepresentativeEndpoints.deleteRepresentative(id));

export const useAddRepresentative = () =>
  usePost<IRepresentative, IRepresentative>(
    RepresentativeEndpoints.addRepresentative()
  );
